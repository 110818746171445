import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'fas fa-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles:'2,3,4' },
  { path: '/datos-generales', title: 'Datos generales', icon: 'far fa-building', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles:'2,3'},
  { path: '/facturas', title: 'Facturas', icon: 'far fa-file-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] ,roles:'2,3,4'},
  { path: '/agregar-factura', title: 'Agregar factura', icon: 'fas fa-file-upload', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles:'2,3' },
  { path: '/generar-carta', title: 'Generar carta', icon: 'fas fa-print', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles:'2,3,4,5' },
  { path: '/reportes', title: 'Reportes', icon: 'fas fa-clipboard-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles:'1,2,7' },
  { path: '/graficas', title: 'Gráficas', icon: 'fas fa-chart-line', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles:'1,2,7' }
];

/*
idRol	Descripcion
1	root
2	Administrador
3	Gestor CN
4	Consulta
5	Operadora
6	Disponible
7	Analista
*/