export class CostoDeCompraModel {
    public idCFDIDetalle: number;
    public idCostoDetalle: number;
    public idActividad: number;
    public descripcion: string;
    public idProveedor: number;
    public vmSj: number;
    public pcnmSj: number;
    public pcnmSjxVMSj: number;
    
    constructor() { }
}